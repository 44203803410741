import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { useCountryCodeAtom, useFormMessageAtom } from '$hooks/state';
import { CART_QUERY_KEY } from '$src/constants';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { useCountryCodeContextFromLocalStorage } from '$src/hooks/state/useCountryCodeContextFromLocalStorage';
import { gateway } from '$src/http';
import { PrepareCartInput } from '$src/pages/api/[shopId]/prepare-cart';

export const MUTATION_PREPARE_CART_KEY = 'MUTATION/PREPARE_CART';

export const usePrepareCart = () => {
  const { initCorrections } = useCorrectionsAtom();
  const { formMessage } = useFormMessageAtom();
  const queryClient = useQueryClient();
  const { track } = useTrackAmplitude();
  const { country } = useCountryCodeAtom();
  const countryCodeContext = useCountryCodeContextFromLocalStorage() ?? formMessage?.countryCode ?? country;
  const updateCartId = useCartIdUpdater();

  const mutation = useMutation({
    mutationFn: (cartInput: PrepareCartInput) =>
      gateway.post<Cart>(`/api/${formMessage?.shopId}/prepare-cart`, {
        cart: cartInput.cart,
        countryCodeContext: countryCodeContext,
      }),
    mutationKey: [MUTATION_PREPARE_CART_KEY],
    onSuccess: ({ data }) => {
      updateCartId(data.id);
      if (data?.corrections?.length) initCorrections(data?.corrections);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
      /* we do it here, as global MutationCache onSuccess callback is executed before this one. So, when it's executed,
         cartId state atom is not already set */
      if (data?.shipping?.ready === false) {
        queryClient.invalidateQueries({ queryKey: [CART_QUERY_KEY, data.id] });
      }
    },
  });

  return mutation;
};
