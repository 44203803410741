function g(e, n) {
  return 0 .toLocaleString(e, {
    style: "currency",
    currency: n,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).replace(/\d/g, "").trim();
}
function D(e) {
  return typeof e == "string" ? parseFloat(e) === 0 : !1;
}
function p(e, n) {
  const { currency: t = "EUR" } = n, a = n.locale ?? (t === "EUR" ? "fr-FR" : "en-US");
  return new Intl.NumberFormat(a, {
    currency: t,
    style: "currency",
    maximumFractionDigits: 2
  }).format(e);
}
function w(e) {
  return e.toString().replace(".", ",");
}
const S = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  formatDecimal: w,
  formatPrice: p,
  getCurrencySymbol: g,
  isFree: D
}, Symbol.toStringTag, { value: "Module" }));
function s(e) {
  try {
    const n = window[e], t = "__storage_test__";
    return n.setItem(t, t), n.removeItem(t), !0;
  } catch {
    return !1;
  }
}
function h(e, n, t) {
  s(e) && window[e].setItem(n, JSON.stringify(t));
}
function x(e, n) {
  if (!s(e))
    return null;
  const t = window[e].getItem(n);
  return t ? JSON.parse(t) : null;
}
function A(e, n, t, a) {
  if (!s(e))
    return;
  const r = Date.now();
  window[e].setItem(n, JSON.stringify({ data: JSON.stringify(t), expiry: r + a }));
}
function O(e, n) {
  if (!s(e))
    return null;
  const t = window[e].getItem(n);
  if (t) {
    const a = JSON.parse(t);
    return Date.now() > a.expiry ? (y("localStorage", n), null) : JSON.parse(a.data);
  }
  return null;
}
function y(e, n) {
  s(e) && window[e].removeItem(n);
}
const T = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  clearStorage: y,
  getStorage: x,
  getStorageWithTtl: O,
  setStorage: h,
  setStorageWithTtl: A,
  storageAvailable: s
}, Symbol.toStringTag, { value: "Module" })), v = (e, n) => {
  let t = null;
  return (...a) => {
    window.clearTimeout(t), t = window.setTimeout(() => {
      e.apply(null, a);
    }, n);
  };
}, b = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  debounce: v
}, Symbol.toStringTag, { value: "Module" })), o = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
function I(e, n) {
  let t = (e + 1) % o.length;
  for (; !n.includes(o[t] ?? ""); )
    t = (t + 1) % o.length;
  return t;
}
function M(e, n, t, a) {
  const r = e.getDay(), i = o[r], l = new Date(e);
  if (l.setDate(l.getDate() + n), e.getHours() >= t || !a.includes(i ?? "")) {
    const u = (I(r, a) - r + 7) % 7 || 1;
    return l.setDate(l.getDate() + u), l.setHours(0), l;
  }
  return l;
}
function _(e, n, t) {
  let a = 0;
  const r = new Date(e);
  for (; a < n; )
    r.setDate(r.getDate() + 1), t.includes(o[r.getDay()] ?? "") && a++;
  return r;
}
function N(e, n) {
  const t = /* @__PURE__ */ new Date(), a = e.warehouse.custom[n] ?? e.warehouse.default;
  let r = t, i = t.getDay();
  for (; !a[o[i]]; )
    r.setDate(r.getDate() + 1), i = r.getDay();
  const { preparationTime: l, cutOffTime: c } = a[o[i]];
  r = M(r, l, c, [
    o[i]
  ]);
  let u = /* @__PURE__ */ new Date("9999-12-31");
  return e.carrier.forEach((f) => {
    if (f.active) {
      const d = _(r, f.shippingTime, f.deliveryDays);
      d < u && (u = d);
    }
  }), u;
}
function m(e, n) {
  let t = 0;
  for (; t < 7; ) {
    const a = (n + 1 + t) % 7, r = o[a];
    if (e[r])
      return {
        nextAvailableDay: r,
        cutOffTime: e[r].cutOffTime
      };
    t++;
  }
  return null;
}
function P(e, n) {
  const t = /* @__PURE__ */ new Date(), a = e.warehouse.custom[n] ?? e.warehouse.default, r = t.getDay();
  let i = a[o[r]] && t.getHours() < a[o[r]].cutOffTime ? {
    nextAvailableDay: o[r],
    cutOffTime: a[o[r]].cutOffTime
  } : m(a, r);
  if (!i)
    return -1;
  let l = o.indexOf(i.nextAvailableDay), c = (l - r + 7) % 7;
  if (c === 0 && t.getHours() >= a[o[r]].cutOffTime) {
    if (i = m(a, r), !i)
      return -1;
    l = o.indexOf(i.nextAvailableDay), c = (l - r + 7) % 7 || 1;
  }
  const u = new Date(t);
  return u.setDate(u.getDate() + c), u.setHours(i.cutOffTime, 0, 0, 0), Math.floor((u.getTime() - t.getTime()) / 1e3);
}
function R(e, n) {
  const t = String(e.getDate()).padStart(2, "0"), a = String(e.getMonth() + 1).padStart(2, "0"), r = e.getFullYear();
  return n.replaceAll("YYYY", r.toString()).replaceAll("DD", t).replaceAll("MM", a);
}
function E(e) {
  const n = Math.floor(e / 3600), t = Math.floor(e % 3600 / 60), a = e % 60;
  return `${n.toString().padStart(2, "0")}:${t.toString().padStart(2, "0")}:${a.toString().padStart(2, "0")}`;
}
function C() {
  return window.ApplePaySession ? window.ApplePaySession.canMakePaymentsWithActiveCard("test").then((e) => e).catch((e) => (console.error("Error checking for active card:", e), !1)) : !1;
}
function F() {
  if (!window.PaymentRequest)
    return Promise.resolve(!1);
  const e = [
    {
      supportedMethods: "https://google.com/pay",
      data: {
        environment: "TEST",
        // Change to PRODUCTION as necessary
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"]
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "example",
                gatewayMerchantId: "exampleGatewayMerchantId"
              }
            }
          }
        ],
        merchantInfo: {
          merchantId: "01234567890123456789",
          merchantName: "JUST"
        }
      }
    }
  ], n = {
    total: {
      label: "Total",
      amount: { currency: "EUR", value: "0.00" }
    }
  };
  return new PaymentRequest(e, n).canMakePayment().then(function(a) {
    return a;
  }).catch(function(a) {
    return console.error("Error with PaymentRequest:", a), !1;
  });
}
const Y = {
  ...T,
  ...b,
  ...S
};
export {
  N as calculateMinimumDeliveryLeadtime,
  P as calculateNextAvailableOrderTimeInSeconds,
  y as clearStorage,
  v as debounce,
  Y as default,
  R as formatDate,
  w as formatDecimal,
  p as formatPrice,
  E as formatSecondsToTime,
  g as getCurrencySymbol,
  x as getStorage,
  O as getStorageWithTtl,
  C as isApplePayAvailable,
  D as isFree,
  F as isGooglePaySupported,
  h as setStorage,
  A as setStorageWithTtl,
  s as storageAvailable
};
