import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import type { PickupPoint, PickupPointSearch, ProviderSettings } from '@getjust/gateway';

import { useFormMessageAtom } from '$src/hooks/state';
import { gateway } from '$src/http';

export const PICKUP_POINTS_QUERY_KEY = 'QUERY/PICKUP_POINTS_QUERY_KEY';

export function usePickupPoints(provider?: ProviderSettings , initialSearch?: PickupPointSearch) {
  const { formMessage } = useFormMessageAtom();
  const [search, setSearch] = useState(initialSearch);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      search?.address1 !== initialSearch?.address1 ||
      search?.city !== initialSearch?.city ||
      search?.zip !== initialSearch?.zip ||
      search?.country !== initialSearch?.country
    ) {
      setSearch(initialSearch);
    }
  }, [initialSearch, search]);

  return useQuery({
    queryKey: [PICKUP_POINTS_QUERY_KEY, provider, search],
    enabled: Boolean(provider) && Boolean(search),
    retry: 3,
    queryFn: async () => {
      const address1Reshaped =
        search?.address1?.replace(
          /\b(bis|ter|quater|quinquies|sexies|septies|octies|novies|decies|duplex|triplex)\b/gi,
          '',
        ) ?? '';
      const response = await gateway.get<PickupPoint[]>(
        `/api/${formMessage?.shopId}/search-pickup-points?search=${encodeURIComponent(
          JSON.stringify({ ...search, address1: address1Reshaped }),
          )}&provider=${encodeURIComponent(JSON.stringify(provider))}`,
      );
      // Mapbox and some pickup providers addresses don't match only use city and country
      if (!response.data.length && search?.address1.length) {
        const updatedSearch = { ...search, address1: '' };
        setSearch(updatedSearch);
        queryClient.invalidateQueries({ queryKey: [PICKUP_POINTS_QUERY_KEY] });
      }
      return response.data;
    },
  });
}
